import { apiUrl, queryClient, handleFetch } from "@api/apiConfig"
import { IChat } from "@api/chat/schema"
import { transform } from "@api/chat/useChats"
import { invalidateWillLoseTrialQuery } from "@api/user-subscriptions/useWillLoseTrial"
import { useUser } from "@api/user/useUser"
import { Photo } from "@capacitor/camera"
import { PickedFile } from "@capawesome/capacitor-file-picker"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { getBlob, pickedFileToUrl } from "@utils"

function generateRandomString(length: number) {
    return Math.random()
        .toString(36)
        .substring(2, 2 + length)
}

function generateRandomId() {
    return Math.floor(Math.random() * 1000000)
}

type ICreate = {
    intent_type: string | null
    message: string
    photos: Photo[]
    files: PickedFile[]
}

export function useCreateChat(projectId: number | null) {
    const authHeader = useAuthHeader()
    const { data: user } = useUser()

    return useMutation({
        mutationFn: async (params: ICreate) => {
            if (!authHeader || !projectId) {
                return null
            }

            const { message, photos, files, intent_type } = params
            const formData = new FormData()

            formData.append("projectId", projectId.toString())
            formData.append("message", message)

            if (intent_type) {
                formData.append("intent_type", intent_type)
            }

            for (const { webPath, format } of photos) {
                if (!webPath) {
                    throw new Error("Fehler beim Lesen des Fotos")
                }

                formData.append("files.files", await getBlob(webPath), `${generateRandomString(32)}.${format}`)
            }

            for (const file of files) {
                formData.append("files.files", await getBlob(pickedFileToUrl(file)), file.name)
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/chats`, {
                    method: "post",
                    body: formData,
                    headers: {
                        ...authHeader,
                    },
                })
            )
        },
        onMutate: async (params) => {
            if (!projectId) {
                return
            }

            const { message, intent_type = null, photos, files } = params
            await queryClient.cancelQueries({ queryKey: ["chats", projectId] })
            const currentChats = queryClient.getQueryData<IChat[]>(["chats", projectId])

            queryClient.setQueryData<IChat[]>(["chats", projectId], (old) => {
                if (!old) {
                    return []
                }

                const updated = [...old]

                if (intent_type !== "init_data_collection") {
                    const photoUrls = photos.map((photo) => ({
                        url: photo.webPath,
                    }))

                    const fileUrls = files.map((file) => ({
                        name: file.name,
                        url: pickedFileToUrl(file),
                    }))

                    const userId = generateRandomId()
                    updated.push({
                        displayKey: userId,
                        id: userId,
                        role: "user",
                        intent_type: intent_type,
                        content: message,
                        sender: {
                            displayName: user?.displayName,
                            avatar: user?.avatar,
                        },
                        lastUpdate: new Date(Date.now()),
                        files: [...photoUrls, ...fileUrls],
                        display: "default",
                        updated_fields: [],
                    })
                }

                const assistantId = generateRandomId()
                updated.push({
                    // add empty message to display loading dots for Energuide
                    displayKey: assistantId,
                    id: assistantId,
                    role: "assistant",
                    intent_type: null,
                    lastUpdate: new Date(Date.now()),
                    files: [],
                    display: "default",
                    updated_fields: [],
                })

                return updated
            })

            return currentChats
        },
        onSuccess: async (chatResponses: { data: Array<any>; meta: any }) => {
            const responses: IChat[] = chatResponses.data
                .map((v) => {
                    const chat = transform(v)
                    return chat
                })
                .filter((v) => Number.isInteger(v.id))

            await invalidateWillLoseTrialQuery(authHeader, "token")

            queryClient.setQueryData<IChat[]>(["chats", projectId], (old?: IChat[]) => {
                if (!old) {
                    return []
                }

                // get old messages and copy display key to new messages
                // this prevents react from re-animating the messages
                let messagesToReplace = 1 // At least the assistant message
                if (responses[0].intent_type !== "init_data_collection") {
                    messagesToReplace = 2 // Both user and assistant messages
                }

                const messagesToRemove = old?.slice(-messagesToReplace)
                const remainingMessages = old?.slice(0, -messagesToReplace)

                for (let i = 0; i < messagesToReplace; i++) {
                    responses[i].displayKey = messagesToRemove?.at(i)?.displayKey
                }

                const newChats = [...remainingMessages, ...responses]
                return newChats
            })
        },
        onError: async () => {
            if (!projectId) {
                return
            }

            await queryClient.invalidateQueries({
                queryKey: ["chats", projectId],
            })
        },
    })
}
