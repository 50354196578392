import { apiUrl, handleFetch } from "@api/apiConfig"
import { IChat } from "@api/chat/schema"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "sonner"

export function useEditChatMessage() {
    const queryClient = useQueryClient()
    const authHeader = useAuthHeader()

    return useMutation({
        mutationFn: async (args: { answer: string; chatId: number; projectId: number | null }) => {
            const { answer, chatId, projectId } = args
            if (!authHeader || !projectId) {
                return null
            }

            // Perform the API call to update the message
            const resp = await handleFetch(() =>
                fetch(`${apiUrl}/chat/${projectId}`, {
                    method: "PATCH",
                    body: JSON.stringify({
                        answer,
                        chatId,
                    }),
                    headers: {
                        "Content-Type": "application/json", // Make sure to set the Content-Type for JSON
                        ...authHeader,
                    },
                })
            )
            return resp
        },
        // Optimistically update the local cache for the chat
        onMutate: async ({ answer, chatId, projectId }) => {
            if (!projectId) return
            await queryClient.cancelQueries({ queryKey: ["chats", projectId] })

            const previousChats = queryClient.getQueryData<IChat[]>(["chats", projectId])
            queryClient.setQueryData<IChat[]>(
                ["chats", projectId],
                (old) => old?.map((chat) => (chat.id === chatId ? { ...chat, content: answer } : chat)) ?? []
            )

            return { previousChats }
        },
        onError: (_err, variables, context) => {
            // If there's an error, rollback to the previous state
            if (context?.previousChats) {
                queryClient.setQueryData(["chats", variables.projectId], context.previousChats)
            }
            toast.error("Fehler beim Speichern der Antwort")
        },
        onSuccess: (variables) => {
            toast.success("Antwort erfolgreich gespeichert")
            // Invalidate the chat query to ensure it reflects the latest server state
            queryClient.invalidateQueries({ queryKey: ["chats", variables.projectId] })
        },
        onSettled: () => {
            // Refetch the chats regardless of success or failure
            queryClient.invalidateQueries({ queryKey: ["chats"] })
        },
    })
}
