import { useUserSubscription } from "@api/user-subscriptions/useUserSubscription"
import { useMutateUser } from "@api/user/useMutateUser.ts"
import { useUser } from "@api/user/useUser"
import { Button } from "@components/atoms/buttons"
import { EnerGuide, EnerGuideDark } from "@components/atoms/energuide"
import { Heading } from "@components/atoms/typography.tsx"
import { ACCOUNT_TYPES } from "@energuide/shared"
import { useAppState } from "@hooks/useState.ts"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { motion } from "framer-motion"
import React, { useEffect } from "react"
import { useNavigate } from "react-router"

export function AccountTypeSelection() {
    const { data: user } = useUser()
    const setUser = useAppState((state) => state.setUser)
    const navigate = useNavigate()
    const userMutation = useMutateUser()
    const [loading, setLoading] = React.useState<boolean>(false)
    const userSubscription = useUserSubscription()

    useTitlebar({
        title: "",
        mode: "hide",
        showContextMenu: false,
    })
    useEffect(() => {
        if (user?.accountTypeRef !== null && user?.accountTypeRef !== undefined) {
            navigate("/authenticated/chat")
        }
    }, [user])

    const onSubmit = async (accountType: "private" | "company") => {
        const formData = new FormData()
        formData.set("accountTypeRef", accountType === "private" ? "1" : "2")

        try {
            const userData = await userMutation.mutateAsync({
                id: user?.id ?? 0,
                data: formData,
            })

            setUser(userData)

            const { data } = await userSubscription.refetch()

            if (!data) {
                navigate("/auth/subscription")
            } else if (userData?.accountTypeRef?.id === ACCOUNT_TYPES.Enterprise.id) {
                navigate("/auth/register-tutorial")
            } else {
                navigate("/authenticated/chat")
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <EnerGuideDark
                className="absolute inset-0 z-[-1] justify-self-center overflow-hidden"
                energuideClassName="absolute left-[25%] top-[25%] z-[-1] aspect-square w-[150%]"
                layoutId="energuide-dark"
            />
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-60%] lg:top-[-22%] z-[-1] aspect-square justify-self-center w-[18rem] sm:w-[20rem] md:w-[30rem] lg:w-[22rem] xl:w-[25rem] 2xl:w-[40rem]"
                layoutId="energuide"
            />

            <div className="mx-auto grid min-h-full w-full max-w-screen-sm content-center gap-12 pt-2">
                <div className="grid gap-12 rounded-lg px-8 pt-8 ">
                    <motion.div className="grid gap-6">
                        <Heading level="h1" className="text-center">
                            Wähle deinen Account-Typ
                        </Heading>
                        <div className="grid grid-rows-2 gap-4 lg:grid-cols-2 lg:gap-6">
                            <Button variant="primary" onClick={() => onSubmit("private")} loading={loading}>
                                Privat
                                <i className="ri-home-fill"></i>
                            </Button>
                            <Button variant="primary" onClick={() => onSubmit("company")} loading={loading}>
                                Unternehmen
                                <i className="ri-building-fill"></i>
                            </Button>
                        </div>
                    </motion.div>
                </div>
            </div>
        </>
    )
}
