import { IMZeileInput } from "@api/mzeile/schema"
import { useCreateMZeile } from "@api/mzeile/useCreateMZeile"
import { useUpdateMZeile } from "@api/mzeile/useUpdateMZeile"
import { Button } from "@components/atoms/buttons"
import { DropDown } from "@components/atoms/dropdown"
import { Input } from "@components/atoms/input"
import { DataModal } from "@components/organisms/data-modal"
import * as Form from "@radix-ui/react-form"
import React from "react"

const Bauteil_Anlagenteil = [
    "Dach",
    "oberste Geschossdecke",
    "Abseiten",
    "Gauben",
    "Dachfenster",
    "Außenwand gg. Außenluft",
    "Fenster",
    "Rollläden und -kästen",
    "Eingangstür",
    "Nebentür",
    "Kellerdecke",
    "Boden gg. Außenluft",
    "Außenwand gg. Erdreich",
    "Boden gegen Erdreich",
    "Luftundichtigkeiten",
    "Heizung",
    "Wärmeerzeuger",
    "Wärmeverteilung / -abgabe",
    "Warmwasserbereitung",
    "Lüftung",
    "Lüftungskonzept",
    "Lüftungsanlage",
    "Luftverteilung / -abgabe",
    "Kühlung",
    "Kälteerzeugung",
    "Kälteverteilung / -abgabe",
    "Be-/Entfeuchtung",
    "Beleuchtung",
    "Anlagenregelung",
    "Gebäudeautomation",
    "Sonstiges",
]

const Modernisierungskombination = ["in Zusammenhang mit größerer Modernisierung", "als Einzelmaßnahme"]

const toSelectItems = (values: string[]) => {
    return values.map((value) => ({
        value,
        label: value,
    }))
}

type ModernizationDialogProps = {
    projectId: number
    initialData: IMZeileInput
    open: boolean
    onOpenChange: (open: boolean) => void
    id: number | null
}

export function ModernizationDialog(props: ModernizationDialogProps) {
    const { open, onOpenChange, projectId, initialData, id } = props
    const [inputs, setInputs] = React.useState<IMZeileInput>(initialData)
    const createMZeile = useCreateMZeile(projectId)
    const updateMZeile = useUpdateMZeile(projectId)

    React.useEffect(() => {
        setInputs(initialData)
    }, [initialData])

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        try {
            if (id) {
                await updateMZeile.mutateAsync({ id, inputs })
            } else {
                await createMZeile.mutateAsync(inputs)
            }
            onOpenChange(false)
        } catch (error) {
            console.error(error)
        }
    }

    const onBauteilAnlagenteilInput = (value: string) => {
        setInputs({
            ...inputs,
            Bauteil_Anlagenteil: value,
        })
    }

    const onMassnahmenbeschreibungInput: React.FormEventHandler<HTMLInputElement> = (event) => {
        setInputs({
            ...inputs,
            Massnahmenbeschreibung: event.currentTarget.value,
        })
    }

    const onModernisierungskombination = (value: string) => {
        setInputs({
            ...inputs,
            Modernisierungskombination: value,
        })
    }

    const onAmortisationInput: React.FormEventHandler<HTMLInputElement> = (event) => {
        setInputs({
            ...inputs,
            Amortisation: event.currentTarget.value,
        })
    }

    const onSpezifische_KostenInput: React.FormEventHandler<HTMLInputElement> = (event) => {
        setInputs({
            ...inputs,
            Spezifische_Kosten: event.currentTarget.value,
        })
    }

    return (
        <DataModal
            iconClass="ri-add-fill"
            title="Modernisierungen"
            description="Modernisierungsempfehlungen hinzufügen"
            open={open}
            onOpenChange={onOpenChange}
            trigger={<Button variant="primary">Hinzufügen</Button>}
        >
            <Form.Root className="grid gap-8" onSubmit={onSubmit}>
                <div className="grid gap-2">
                    <DropDown
                        name="Bauteil_Anlagenteil"
                        label="Bauteil Anlagenteil"
                        placeholder="Auswählen"
                        value={inputs.Bauteil_Anlagenteil ?? ""}
                        onValueChange={onBauteilAnlagenteilInput}
                        items={toSelectItems(Bauteil_Anlagenteil)}
                    />
                    <Input
                        name="Massnahmenbeschreibung"
                        label="Massnahmenbeschreibung"
                        value={inputs.Massnahmenbeschreibung ?? ""}
                        onInput={onMassnahmenbeschreibungInput}
                    />
                    <DropDown
                        name="Modernisierungskombination"
                        label="Modernisierungskombination"
                        placeholder="Auswählen"
                        value={inputs.Modernisierungskombination ?? ""}
                        onValueChange={onModernisierungskombination}
                        items={toSelectItems(Modernisierungskombination)}
                    />
                    <Input
                        name="Amortisation"
                        label="Amortisation"
                        value={inputs.Amortisation ?? ""}
                        onInput={onAmortisationInput}
                    />
                    <Input
                        name="Spezifische_Kosten"
                        label="Spezifische Kosten"
                        value={inputs.Spezifische_Kosten ?? ""}
                        onInput={onSpezifische_KostenInput}
                    />
                </div>

                <Button variant="primary">Speichern</Button>
            </Form.Root>
        </DataModal>
    )
}
