import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { UserSchema, useUserKey } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

type MutationParams = {
    id: number
    data: FormData
}

export function useMutateUser() {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async (params: MutationParams) => {
            if (!authHeader) {
                return null
            }

            const { id, data } = params
            const response = await handleFetch(() =>
                fetch(`${apiUrl}/users/${id}?populate=accountTypeRef`, {
                    method: "put",
                    body: data,
                    headers: {
                        ...authHeader,
                    },
                })
            )

            return UserSchema.parse(response)
        },
        onSuccess: async () => {
            toast.success("Gespeichert")
            await queryClient.invalidateQueries({
                queryKey: useUserKey,
            })
        },
    })
}
