import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { UsageTrackingLimitSchema } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

const useUsageLimits = () => {
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["user-limits"],
        queryFn: async () => {
            if (!authHeader) {
                return null
            }
            const response = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/usage-tracking/limits`, {
                    method: "GET",
                    headers: {
                        ...authHeader,
                    },
                })

                if (response.status === 404) {
                    return null
                }

                return response
            })

            if (!response) {
                return null
            }

            const parsed = handleParse(() => UsageTrackingLimitSchema.parse(response))
            return parsed
        },
    })
}

export { useUsageLimits }
