import { BackendError } from "@api/apiConfig"
import { register, RegisterSchema, RegisterSchemaType } from "@api/auth/register"
import { useAppState } from "@hooks/useState"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router"
import { toast } from "sonner"
import { AccountTypePage } from "./sub/account-type"
import { Register as RegisterSub } from "./sub/register"

enum Stages {
    Base,
    AccountType,
}

function Register() {
    const [registerData, setRegisterData] = useState<Partial<RegisterSchemaType> | null>(null)
    const registerCmpRef = useRef<HTMLFormElement>(null)
    const setIsLoading = useAppState((state) => state.setIsLoading)
    const navigate = useNavigate()

    const stage = useMemo<Stages>(() => {
        if (registerData) {
            return Stages.AccountType
        } else {
            return Stages.Base
        }
    }, [registerData])

    const setSomeRegisterData = (partial: Partial<RegisterSchemaType>) => {
        if (registerData) {
            setRegisterData({ ...registerData, ...partial })
        } else {
            setRegisterData(partial)
        }
    }

    const onSubmit = useCallback(
        async (data: RegisterSchemaType) => {
            try {
                setIsLoading(true)
                await register(data)
                reset()
                navigate("/auth/login-selection")
                toast.info("Bitte bestätige deine E-Mail Addresse bevor wir weitermachen.")
            } catch (e) {
                if (e instanceof BackendError) {
                    toast.error(e.message)
                }
            } finally {
                setIsLoading(false)
            }
        },
        [navigate, setIsLoading]
    )

    const reset = () => {
        setRegisterData(null)
        registerCmpRef.current?.resetForm()
    }

    useEffect(() => {
        const isBase = stage === Stages.AccountType
        const isRegisterDataOk = RegisterSchema.safeParse(registerData).success
        if (isBase && isRegisterDataOk) {
            const data = RegisterSchema.parse(registerData)
            void onSubmit(data)
        }
    }, [registerData, onSubmit, stage])

    return (
        <div>
            {stage === Stages.Base && <RegisterSub ref={registerCmpRef} onNext={setSomeRegisterData} />}
            {stage === Stages.AccountType && <AccountTypePage onNext={setSomeRegisterData} onPrev={reset} />}
        </div>
    )
}
export { Register }
