import { IFaqHint } from "@api/faq-hints/schema"
import { GradientBackground } from "@components/atoms/gradient"
import { cls } from "@utils"
import { AnimatePresence, motion } from "framer-motion"

type IChatHints = {
    hints: IFaqHint[]
    onClick: (message: string) => void
    disabled?: boolean
    className?: string
}

export function ChatCards(props: IChatHints) {
    const { hints, onClick, disabled = false, className } = props
    const Icon = () => <i className="ri-chat-1-fill text-body1"></i>

    return (
        <AnimatePresence>
            <div className={cls("grid grid-cols-[repeat(auto-fit,minmax(20ch,1fr))] gap-4", className)}>
                {hints.map((hint, idx) => (
                    <motion.div
                        key={hint.id}
                        className="grid gap-4"
                        initial={disabled ? undefined : { y: "50%", opacity: 0 }}
                        animate={disabled ? undefined : { y: "0%", opacity: 1 }}
                        transition={{ delay: 0.2 * idx }}
                    >
                        <GradientBackground
                            variant="dark"
                            className={`flex flex-col gap-4 px-4 py-3 text-textVeryLight ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
                            radius="big"
                            onClick={disabled ? undefined : () => onClick(hint.question)}
                        >
                            <Icon />
                            {hint.question}
                        </GradientBackground>
                    </motion.div>
                ))}
            </div>
        </AnimatePresence>
    )
}
