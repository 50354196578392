import { apiUrl } from "@api/apiConfig"
import { Capacitor } from "@capacitor/core"
import { LinkButton } from "@components/atoms/buttons/link-button.tsx"
import { EnerGuide, EnerGuideDark } from "@components/atoms/energuide"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { Footer } from "@layouts/auth.tsx"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"

export function LoginSelection() {
    useTitlebar({
        title: "",
        mode: "hide",
        showContextMenu: false,
    })

    return (
        <>
            <EnerGuideDark
                className="absolute inset-0 z-[-1] justify-self-center overflow-hidden"
                energuideClassName="absolute left-[-75%] top-[25%] z-[-1] aspect-square w-[150%]"
                layoutId="energuide-dark"
            />
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-10%] lg:top-[-20%] z-[-1] aspect-square w-[18rem] sm:w-[20rem] md:w-[30rem] lg:w-[22rem] xl:w-[25rem] 2xl:w-[40rem] justify-self-center"
                layoutId="energuide"
            />

            <div className="mx-auto min-h-full h-full w-full max-w-screen-sm content-end lg:content-center">
                <div className="grid gap-12 rounded-lg bg-background/20 px-8 pt-8 backdrop-blur-2xl mt-auto">
                    <motion.div className="grid gap-2" layoutId="login-title">
                        <Heading level="h1">Login</Heading>
                        <Text variant="body1" className="text-textLight">
                            Nur noch kurz einloggen, dann kann es direkt losgehen.
                        </Text>
                    </motion.div>

                    <div className="grid grid-cols-3 gap-4">
                        <LinkButton variant="primary" href="/auth/login" className="col-span-full">
                            E-Mail
                        </LinkButton>
                        <LinkButton
                            variant="secondary"
                            href={`${apiUrl}/connect/google`}
                            isExternal={Capacitor.isNativePlatform()}
                            inApp={Capacitor.isNativePlatform()}
                        >
                            <i className="ri-google-fill text-[1.5rem]"></i>
                        </LinkButton>
                        <LinkButton
                            variant="secondary"
                            href={`${apiUrl}/connect/facebook`}
                            isExternal={Capacitor.isNativePlatform()}
                            inApp={Capacitor.isNativePlatform()}
                        >
                            <i className="ri-facebook-fill text-[1.5rem]"></i>
                        </LinkButton>
                        <LinkButton
                            variant="secondary"
                            href={`${apiUrl}/apple-signin-provider/connect`}
                            isExternal={Capacitor.isNativePlatform()}
                            inApp={Capacitor.isNativePlatform()}
                        >
                            <i className="ri-apple-fill text-[1.5rem]"></i>
                        </LinkButton>
                    </div>

                    <Footer>
                        <Text variant="small" className="text-textLight">
                            Du hast noch keinen Account?
                        </Text>
                        <Link to="/auth/register-selection" className="text-white underline">
                            Registrieren
                        </Link>
                    </Footer>
                </div>
            </div>
        </>
    )
}
