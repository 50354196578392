import { queryClient } from "@api/apiConfig"
import { Preferences } from "@capacitor/preferences"
import { IContextMenu } from "@components/molecules/context-menu"
import { IUser } from "@energuide/shared"
import { create } from "zustand"
import { persist, createJSONStorage, StateStorage } from "zustand/middleware"

const CapacitorStorage: StateStorage = {
    async getItem(name) {
        const result = await Preferences.get({
            key: name,
        })
        return result.value
    },

    async setItem(name, value) {
        await Preferences.set({
            key: name,
            value: value,
        })
    },

    async removeItem(name) {
        await Preferences.remove({
            key: name,
        })
    },
}

type ChatMessage = {
    author: string
    timestamp: number
    message: string
    attachment?: string
}

export type Modes = "menu" | "back" | "hide" | "callback"

type AppState = {
    hasSeenIntro: boolean
    setHasSeenIntro: () => void

    token: string
    user: IUser | null
    setAuthentication: (token: string, user: IUser | null) => void
    setUser: (user: IUser | null) => void
    clearAuth: () => void

    isLoading: boolean
    setIsLoading: (loading: boolean) => void

    appbarSize: number[]
    setAppbarSize: (size: number[]) => void

    title: string
    setTitle: (title: string) => void

    backTo: string
    setBackTo: (backTo: string) => void

    backToCallback: (() => void) | null
    setBackToCallback: (callback: (() => void) | null) => void

    mode: Modes
    setMode: (mode: Modes) => void

    showSearchIcon: boolean
    setShowSearchIcon: (show: boolean) => void
    searchOpen: boolean
    setSearchOpen: (open: boolean) => void

    showContextMenu: boolean
    contextMenuProps: IContextMenu
    setShowContextMenu: (show: boolean, contextMenuProps?: IContextMenu) => void

    activeProjectId: number | null
    setActiveProject: (id: number | null) => void

    chatMessages: ChatMessage[]
    clearChatMessages: () => void
    addChatMessages: (chatMessages: ChatMessage[]) => void

    isHydrated: boolean
    setIsHydrated: () => void
}

export const useAppState = create<AppState>()(
    persist(
        (set) => ({
            hasSeenIntro: false,
            setHasSeenIntro() {
                set(() => ({
                    hasSeenIntro: true,
                }))
            },

            token: "",
            user: null,
            setAuthentication(token: string, user: IUser | null) {
                queryClient.clear()
                set(() => ({
                    token,
                    user,
                    activeProjectId: null,
                }))
            },
            setUser(user: IUser | null) {
                set(() => ({
                    user,
                }))
            },
            clearAuth() {
                queryClient.clear()
                set(() => ({
                    token: "",
                    user: null,
                    activeProjectId: null,
                }))
            },

            isLoading: false,
            setIsLoading(loading: boolean) {
                set(() => ({ isLoading: loading }))
            },

            appbarSize: [0, 0],
            setAppbarSize(size: number[]) {
                set(() => ({ appbarSize: size }))
            },

            title: "",
            setTitle(title: string) {
                set(() => ({ title }))
            },

            backTo: "",
            setBackTo(backTo: string) {
                set(() => ({
                    backTo,
                    mode: "back",
                }))
            },

            backToCallback: null,
            setBackToCallback(callback: (() => void) | null) {
                set(() => ({ backToCallback: callback }))
            },

            mode: "menu",
            setMode(mode: Modes) {
                set(() => ({ mode }))
            },

            showSearchIcon: false,
            setShowSearchIcon(show) {
                set(() => ({ showSearchIcon: show }))
            },
            searchOpen: false,
            setSearchOpen(open) {
                set(() => ({ searchOpen: open }))
            },

            showContextMenu: false,
            contextMenuProps: {
                showDataExport: false,
                showProjectOverview: false,
                showProjectSettings: false,
            },
            setShowContextMenu(show, contextMenuProps) {
                set(() => ({ showContextMenu: show, contextMenuProps }))
            },

            activeProjectId: null,
            setActiveProject(id) {
                set(() => ({ activeProjectId: id }))
            },

            chatMessages: [],
            clearChatMessages() {
                set(() => ({
                    chatMessages: [],
                }))
            },
            addChatMessages(chatMessages: ChatMessage[]) {
                set((state) => ({
                    chatMessages: [...state.chatMessages, ...chatMessages],
                }))
            },

            isHydrated: false,
            setIsHydrated() {
                set(() => ({ isHydrated: true }))
            },
        }),
        {
            name: "energuide-preferences",
            storage: createJSONStorage(() => CapacitorStorage),
            onRehydrateStorage: () => (state) => {
                state?.setIsLoading(false)
                state?.setIsHydrated()
            },
        }
    )
)
