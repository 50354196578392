// constants.ts

export const ACCOUNT_TYPES = {
    Private: {
        id: 1,
        name: "Private",
    },
    Enterprise: {
        id: 2,
        name: "Enterprise",
    },
} as const

// Define types from the constants
export type AccountTypeId = (typeof ACCOUNT_TYPES)[keyof typeof ACCOUNT_TYPES]["id"]
export type AccountTypeValue = (typeof ACCOUNT_TYPES)[keyof typeof ACCOUNT_TYPES]["name"]
