import { useCreateCertificateByToken } from "@api/certificate/useCreateCertificateByToken"
import { useWillLoseTrial } from "@api/user-subscriptions/useWillLoseTrial"
import { Button, LinkButton } from "@components/atoms/buttons"
import { Text } from "@components/atoms/typography.tsx"
import { DataModal } from "@components/organisms/data-modal"
import { useScroll } from "@hooks/useScroll"
import * as Form from "@radix-ui/react-form"
import { useCallback, useEffect, useState } from "react"
import { toast } from "sonner"

interface Props {
    onNext: () => void
    certificateId: number
    previewSrc: string
}

export function CertificateFreeGenerate(props: Props) {
    const { onNext, certificateId, previewSrc } = props
    const [isCreating, setIsCreating] = useState(false)
    const { data: willLoseTrial } = useWillLoseTrial({ resource: "certificate" })
    const [showTrialModal, setShowTrialModal] = useState(false)

    const { manualScroll } = useScroll("bottom", false, "smooth")

    useEffect(() => {
        manualScroll("bottom")
    }, [manualScroll])

    const error = useCallback(async () => {
        toast.error("Zahlung fehlgeschlagen!")
        setIsCreating(false)
    }, [])

    const { mutateAsync } = useCreateCertificateByToken(certificateId)
    async function generateCertificateByToken() {
        if (willLoseTrial?.status === "will-loose") {
            setShowTrialModal(true)
            return
        }
        await generate()
    }
    async function generate() {
        setIsCreating(true)
        try {
            await mutateAsync()
            onNext()
        } catch (e) {
            void error()
            console.error(e)
        } finally {
            setIsCreating(false)
        }
    }
    async function downloadPreview() {
        onNext()
    }

    return (
        <div>
            <p className="mb-4 mt-2 text-textLight">
                Dein vollwertiger Ausweis ohne Wasserzeichen steht bereit. Schließe die Bezahlung ab, um ihn sofort
                herunterzuladen.
            </p>
            <div>
                <TrialModal
                    open={showTrialModal}
                    onOpenChange={(open) => {
                        if (!open) {
                            setShowTrialModal(false)
                        }
                    }}
                    onConfirm={generate}
                />
                <div className="flex justify-between">
                    <div className="flex w-full flex-col gap-2">
                        <Button
                            loading={isCreating}
                            disabled={isCreating}
                            variant="primary"
                            onClick={generateCertificateByToken}
                            className="w-full px-8"
                        >
                            Generiere Zertifikat
                        </Button>
                        <LinkButton
                            variant="secondary"
                            onClick={downloadPreview}
                            className="w-full px-8"
                            href={previewSrc}
                            isExternal={true}
                        >
                            Vorschau
                        </LinkButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

function TrialModal(props: { open: boolean; onOpenChange: (open: boolean) => void; onConfirm: () => void }) {
    const { open, onOpenChange, onConfirm } = props

    return (
        <DataModal
            iconClass="ri-alert-line"
            title="Hinweis"
            description=""
            open={open}
            onOpenChange={onOpenChange}
            trigger
        >
            <Form.Root
                className="grid gap-6"
                onSubmit={(e) => {
                    e.preventDefault()
                    onConfirm()
                }}
            >
                <Text variant="body1" className="text-warning">
                    Durch das Abschicken der Nachricht verlierst du dein Recht auf eine Erstattung.
                </Text>
                <Button variant="primary" type="submit">
                    Verstanden
                </Button>
            </Form.Root>
        </DataModal>
    )
}
