import * as Label from "@radix-ui/react-label"
import * as Select from "@radix-ui/react-select"
import { cls } from "@utils"
import { GradientBorder } from "./gradient"

type Item = {
    value: string
    label: string
}

type IDropDown = Select.SelectProps & {
    label: string
    placeholder: string
    items: Item[]
}

export function DropDown(props: IDropDown) {
    const { label, placeholder, items, ...selectProps } = props

    return (
        <div className="grid gap-2.5">
            <Label.Root className="font-label text-label text-white">{label}</Label.Root>
            <Select.Root {...selectProps}>
                <GradientBorder variant="dark" radius="small">
                    <Select.Trigger
                        className={cls(
                            `relative bg-backgroundLight px-4 py-3 text-body2 text-text rounded-lg grid grid-cols-[1fr,auto] justify-items-start
                            items-center`
                        )}
                    >
                        <Select.Value placeholder={placeholder} className="justify-self-start" />
                        <Select.Icon className="justify-self-end">
                            <i className="ri-arrow-down-s-line"></i>
                        </Select.Icon>
                    </Select.Trigger>
                </GradientBorder>
                <Select.Portal>
                    <Select.Content className="absolute z-50" position="popper" align="start">
                        <Select.Viewport className="max-h-96 bg-background w-[max-content] p-4">
                            {items.map((item) => (
                                <Select.Item
                                    value={item.value}
                                    className="hover:bg-primaryAccent2/30 px-4 py-2 rounded-lg"
                                >
                                    <Select.ItemText>{item.label}</Select.ItemText>
                                </Select.Item>
                            ))}
                        </Select.Viewport>
                    </Select.Content>
                </Select.Portal>
            </Select.Root>
        </div>
    )
}
