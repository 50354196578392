import { z } from "zod"
import { Ref } from "../common"
import { SubscriptionIntervalSchema } from "../subscription"

const OfferPriceSchema = z.object({
    id: z.number(),
    offerRef: Ref(),
    interval: SubscriptionIntervalSchema.nullable(),
    displayPrice: z.number().positive(),
    displayDiscountedPrice: z.number().positive(),
})

export { OfferPriceSchema }
