import { IMZeile } from "@api/mzeile/schema"
import { Button } from "@components/atoms/buttons"
import { PropsWithChildren } from "react"

function ModernizationAttribute(props: PropsWithChildren) {
    const { children } = props
    return <span className="text-textLight">{children}</span>
}

function ModernizationContent(props: PropsWithChildren) {
    const { children } = props
    return <span className="overflow-hidden text-ellipsis whitespace-nowrap text-text">{children}</span>
}

type ModernizationCardProps = {
    mZeile: IMZeile
    onDelete: (id: number) => void
    onUpdate: (mZeile: IMZeile) => void
}

export function ModernizationCard(props: ModernizationCardProps) {
    const { mZeile, onDelete, onUpdate } = props
    const {
        Bauteil_Anlagenteil,
        Massnahmenbeschreibung,
        Modernisierungskombination,
        Amortisation,
        Spezifische_Kosten,
    } = mZeile

    return (
        <div className="grid gap-4 p-4 bg-primaryAccent3/10 rounded-lg">
            <div className="grid gap-y-0 gap-x-4 sm:grid-cols-[auto,1fr]">
                <ModernizationAttribute>Bauteil Anlagenteil</ModernizationAttribute>
                <ModernizationContent>{Bauteil_Anlagenteil}</ModernizationContent>

                <ModernizationAttribute>Massnahmenbeschreibung</ModernizationAttribute>
                <ModernizationContent>{Massnahmenbeschreibung}</ModernizationContent>

                <ModernizationAttribute>Modernisierungskombination</ModernizationAttribute>
                <ModernizationContent>{Modernisierungskombination}</ModernizationContent>

                <ModernizationAttribute>Amortisation</ModernizationAttribute>
                <ModernizationContent>{Amortisation}</ModernizationContent>

                <ModernizationAttribute>Spezifische Kosten</ModernizationAttribute>
                <ModernizationContent>{Spezifische_Kosten}</ModernizationContent>
            </div>

            <div className="flex gap-2">
                <Button variant="warning" onClick={() => onDelete(mZeile.id)}>
                    <i className="ri-delete-bin-line"></i>
                    Delete
                </Button>
                <Button variant="secondary" onClick={() => onUpdate(mZeile)}>
                    <i className="ri-edit-2-fill"></i>
                    Edit
                </Button>
            </div>
        </div>
    )
}
