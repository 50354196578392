import { z } from "zod"
import { Ref } from "../common"

export const projectIdPattern = "[0-9]+"

export const ProjectSchema = z.object({
    name: z.string(),
    project_authorization: Ref(z.any()),
    cover: z.any(),
    construction_year: z.string(),
    size: z.number(),
    heating_system: z.string(),
    chats: Ref(),
    energy_certificates: Ref(),
    GDaten: Ref(),
    GEGDaten: Ref(),
    sBuilding: Ref(),
})
