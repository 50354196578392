import { useMutateUserSubscription } from "@api/user-subscriptions/useMutateUserSubscription"
import { useUserSubscription } from "@api/user-subscriptions/useUserSubscription"
import { Button } from "@components/atoms/buttons"
import { ContentOffset } from "@components/molecules/content-offset"
import { DataModal } from "@components/organisms/data-modal.tsx"
import {
    ACCOUNT_TYPES,
    EnterpriseSubscriptionId,
    isFreeSubscription,
    PrivateSubscriptionId,
    SubscriptionInterval,
} from "@energuide/shared"
import { usePaymentChannel } from "@hooks/usePaymentChannel"
import { useAppState } from "@hooks/useState"
import { useStripe } from "@libs/stripe-lib"
import * as Form from "@radix-ui/react-form"
import { useCallback, useMemo, useState } from "react"
import { toast } from "sonner"
import { EnterpriseSubscription } from "./sub/enterprise"
import { PrivateSubscription } from "./sub/private"

function SubscriptionPage() {
    const user = useAppState((state) => state.user)
    const accountType = useMemo(() => user?.accountTypeRef?.id, [user])
    const setIsLoading = useAppState((state) => state.setIsLoading)
    const { listen, close } = usePaymentChannel()
    const { data: userSubscription } = useUserSubscription()
    const { mutateAsync: setUserSubscription } = useMutateUserSubscription()

    const [open, setOpen] = useState(false)
    const [pendingSubscription, setPendingSubscription] = useState<{
        subscriptionId: PrivateSubscriptionId | EnterpriseSubscriptionId
        interval: SubscriptionInterval
    } | null>(null)

    const success = useCallback(() => {
        toast.success("Zahlung erfolgreich!")
        close()
    }, [close])

    const error = useCallback(async () => {
        toast.error("Zahlung fehlgeschlagen!")
        setIsLoading(false)
        close()
    }, [close, setIsLoading])

    const listenForPaymentSuccess = useCallback(
        (sessionId: string) => {
            listen((msg) => {
                if (msg.id === sessionId) {
                    if (msg.status === "success") {
                        success()
                    } else if (msg.status === "error") {
                        void error()
                    }
                } else {
                    console.warn("received msg from listen(msg) with different sessionId: ", msg)
                }
            })
        },
        [listen, error, success]
    )

    const stripeUtils = useStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY).useSubscriptionOrder()
    const onSubmit = useCallback(
        async (subscriptionId: PrivateSubscriptionId | EnterpriseSubscriptionId, interval: SubscriptionInterval) => {
            if (userSubscription) {
                setPendingSubscription({ subscriptionId, interval })
                setOpen(true)
                return
            }

            if (isFreeSubscription(subscriptionId)) {
                await setUserSubscription({ subscriptionId })
                return
            }

            setIsLoading(true)
            try {
                const { checkout } = await stripeUtils.orderSubscription({ subscriptionId, interval })

                if (!checkout) {
                    toast.error("Fehler beim Kauf, bitte erneut versuchen.")
                    return
                }

                const sessionId = checkout.id
                listenForPaymentSuccess(sessionId)

                window.open(`/payment/checkout?sessionId=${sessionId}`, "_blank")
            } catch (e) {
                console.error(e)
            } finally {
                setIsLoading(false)
            }
        },
        [
            userSubscription,
            setUserSubscription,
            setIsLoading,
            stripeUtils,
            listenForPaymentSuccess,
            setPendingSubscription,
            setOpen,
        ]
    )

    const handlePending = useCallback<React.FormEventHandler<HTMLFormElement>>(
        async (event) => {
            event.preventDefault()
            if (pendingSubscription) {
                setIsLoading(true)
                try {
                    if (isFreeSubscription(pendingSubscription.subscriptionId)) {
                        await setUserSubscription({ subscriptionId: pendingSubscription.subscriptionId })
                    } else {
                        const { checkout } = await stripeUtils.orderSubscription({
                            subscriptionId: pendingSubscription.subscriptionId,
                            interval: pendingSubscription.interval,
                        })

                        if (!checkout) {
                            toast.error("Fehler beim Kauf, bitte erneut versuchen.")
                            return
                        }

                        const sessionId = checkout.id
                        listenForPaymentSuccess(sessionId)

                        window.open(`/payment/checkout?sessionId=${sessionId}`, "_blank")
                    }
                } catch (e) {
                    console.error(e)
                } finally {
                    setIsLoading(false)
                    setOpen(false)
                    setPendingSubscription(null)
                }
            }
        },
        [pendingSubscription, setUserSubscription, setIsLoading, stripeUtils, listenForPaymentSuccess]
    )

    const onUnsubscribe = useCallback(async () => {
        setIsLoading(true)
        try {
            const response = await stripeUtils.cancelSubscription()
            if (!response) {
                toast.error("Fehler beim Kündigen des Abonnements, bitte erneut versuchen.")
            }

            toast.success("Abonnement erfolgreich gekündigt!")
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, stripeUtils])

    return (
        <>
            <ContentOffset safeAreas={false} offsetAppbar={true}>
                <DataModal
                    iconClass="ri-add-line"
                    title="Abonnement wechseln"
                    description="Wenn du Fortfährst verliest du alle deine Vorteile des aktuellen Abonnements."
                    open={open}
                    onOpenChange={setOpen}
                    trigger={null}
                >
                    <Form.Root className="grid gap-6" onSubmit={handlePending}>
                        <Button variant="primary">Fortfahren</Button>
                    </Form.Root>
                </DataModal>

                {accountType === ACCOUNT_TYPES.Enterprise.id && (
                    <EnterpriseSubscription
                        onSubmit={onSubmit}
                        onUnsubscribe={onUnsubscribe}
                        userSubscription={userSubscription ?? null}
                    />
                )}
                {accountType === ACCOUNT_TYPES.Private.id && (
                    <PrivateSubscription
                        onSubmit={onSubmit}
                        onUnsubscribe={onUnsubscribe}
                        userSubscription={userSubscription ?? null}
                    />
                )}
            </ContentOffset>
        </>
    )
}
export { SubscriptionPage }
