import { z } from "zod"
import { ACCOUNT_TYPES } from "./constants"

export const AccountTypeIdSchema = z.union([
    z.literal(ACCOUNT_TYPES.Private.id),
    z.literal(ACCOUNT_TYPES.Enterprise.id),
])

export const AccountTypeValueSchema = z.enum([ACCOUNT_TYPES.Private.name, ACCOUNT_TYPES.Enterprise.name])

export const AccountTypeSchema = z.object({
    id: AccountTypeIdSchema,
    type: AccountTypeValueSchema,
    pricesIncludeVAT: z.boolean(),
})

export { ACCOUNT_TYPES }
