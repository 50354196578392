import { apiUrl, handleFetch } from "@api/apiConfig"
import { IProduct, ProductId } from "@energuide/shared"
import { OrderSchema, IOrder } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

export type IUseOrder = {
    productId: ProductId
    projectId: number
}

export type IOrderResult = {
    order: IOrder
    product: IProduct
}

export function useOrder(params: IUseOrder) {
    const { productId, projectId } = params
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["order", productId, projectId],
        queryFn: async (): Promise<IOrder | null> => {
            try {
                const response = await handleFetch(async () => {
                    const response = await fetch(`${apiUrl}/order?productId=${productId}&projectId=${projectId}`, {
                        method: "GET",
                        headers: {
                            ...authHeader,
                        },
                    })
                    // don't show error when no entry found
                    if (response.status === 404) {
                        return null
                    }

                    return response
                })

                if (!response) {
                    return null
                }
                return OrderSchema.parse(response)
            } catch (e) {
                console.warn("outer error ", e)
                return null
            }
        },
    })
}
