import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"
import { IMZeile, MZeilesSchema } from "./schema"

export function transform(mzeile: any) {
    const transformed: IMZeile = {
        id: mzeile.id,
        ...mzeile.attributes,
    }

    return transformed
}

type IUseMZeiles = {
    enabled?: boolean
}

export function useMZeiles(projectId: number | null, params?: IUseMZeiles) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["mzeiles", projectId],
        queryFn: async () => {
            const { data } = await handleFetch(() =>
                fetch(`${apiUrl}/m-zeiles?projectId=${projectId}`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            const mzeiles = handleParse(() => MZeilesSchema.parse(data.map(transform)))
            return mzeiles
        },
        enabled: enabled && !!authHeader && !!projectId,
    })
}
