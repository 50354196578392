import { apiUrl, queryClient, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

export function useDeleteMZeile(projectId: number | null) {
    const authHeader = useAuthHeader()

    return useMutation({
        mutationFn: async (id: number) => {
            if (!authHeader) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/m-zeiles/${id}`, {
                    method: "delete",
                    headers: {
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            if (!projectId) {
                return
            }

            toast.success("Modernisierung gelöscht")
            await queryClient.invalidateQueries({
                queryKey: ["mzeiles", projectId],
            })
        },
        onError: async () => {
            if (!projectId) {
                return
            }

            toast.error("Löschen fehlgeschlagen")
            await queryClient.invalidateQueries({
                queryKey: ["mzeiles", projectId],
            })
        },
    })
}
