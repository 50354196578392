import { z } from "zod"

const ISO8601DateSchema = z.string().regex(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/)

const ImageSchema = z.object({
    name: z.string().nullish(),
    url: z.string().nullish(),
    width: z.number().nullish(),
    height: z.number().nullish(),
})

//Notes: When we fetch the Ref is always either null | undefined or the actual object
// But when we create the Ref the value is a string or number.
// What is the right way to distinguish between the two and provide the correct type?
const Ref = <T extends z.ZodTypeAny>(schema?: T) => {
    // When fetching, the ID can be null, undefined, or the full object.
    const fetchedSchema = z.union([
        z.null(), // For null or undefined references when fetching
        z.undefined(), // For optional values
        schema ?? z.any(), // If the full object is provided (when fetching)
    ])

    // When creating, the reference is an ID (either string or number)
    const createSchema = z.union([z.string(), z.number()])

    // Combine both schemas in a way that can handle either case
    return z.union([fetchedSchema, createSchema]).optional()
}

export { ISO8601DateSchema, ImageSchema, Ref }
