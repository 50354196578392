import { Button, LinkButton } from "@components/atoms/buttons"
import { Text } from "@components/atoms/typography"
import { ContextMenu, IContextMenu } from "@components/molecules/context-menu"
import { Menu } from "@components/organisms/menu"
import { useSafeArea } from "@hooks/useSafeArea"
import { useSize } from "@hooks/useSize"
import { Modes, useAppState } from "@hooks/useState"
import { cls } from "@utils"
import { motion } from "framer-motion"
import React from "react"

type IAppbar = {
    className?: string
    title?: string
    mode: Modes
    backTo?: string
    backToCallback: (() => void) | null
    style?: React.CSSProperties
    safeSpace?: string
    showSearchIcon?: boolean
} & (
    | {
          showContextMenu: false
      }
    | {
          showContextMenu: true
          contextMenuProps: IContextMenu
      }
)

export function Appbar(props: IAppbar) {
    const { className, backTo, mode, title, style, showContextMenu, showSearchIcon, backToCallback } = props
    const isHidden = mode === "hide"
    const inset = useSafeArea()
    const safeSpace = `${inset.top}px`
    const { ref, size } = useSize<HTMLDivElement>()
    const setAppbarSize = useAppState((state) => state.setAppbarSize)
    const searchOpen = useAppState((state) => state.searchOpen)
    const setSearchOpen = useAppState((state) => state.setSearchOpen)

    React.useLayoutEffect(() => {
        setAppbarSize(size)
    }, [size, setAppbarSize])

    return (
        <motion.div
            className={cls(
                "gradient-border-dark absolute left-0 right-0 z-40 grid items-center border-b bg-background/50 backdrop-blur-2xl",
                className
            )}
            animate={{
                transform: isHidden ? "translateY(-100%)" : "translateY(0%)",
                opacity: isHidden ? 0 : 1,
            }}
            transition={{ type: "tween" }}
            ref={ref}
        >
            <div style={{ height: safeSpace }}></div>
            <div className={cls("grid grid-cols-auto-1fr-auto items-center py-2")} style={style}>
                {mode === "menu" && <Menu mode="mobile" />}
                {mode === "back" && (
                    <LinkButton
                        href={backTo ?? ""}
                        onClick={() => backToCallback && backToCallback()}
                        variant="tertiary"
                        className="grid items-center text-[2rem] text-text"
                        data-testid="back-button"
                    >
                        <i className="ri-arrow-left-s-line text-[1.5rem]"></i>
                    </LinkButton>
                )}
                {mode === "callback" && (
                    <Button
                        onClick={() => backToCallback && backToCallback()}
                        variant="tertiary"
                        className="grid items-center text-[2rem] text-text"
                    >
                        <i className="ri-arrow-left-s-line text-[1.5rem]"></i>
                    </Button>
                )}

                <div className="flex items-center gap-4 justify-self-center">
                    {title ? <i className="ri-home-fill text-[1.5rem] leading-none"></i> : null}
                    <Text variant="strong2" className="text-text">
                        {title}
                    </Text>
                </div>
                <div className="flex items-center gap-2">
                    {showSearchIcon ? (
                        <Button
                            variant="freeform"
                            onClick={() => setSearchOpen(!searchOpen)}
                            data-testid="search-button"
                        >
                            <i className="ri-search-line text-[1.2rem] "></i>
                        </Button>
                    ) : null}
                    {showContextMenu ? <ContextMenu {...props.contextMenuProps} /> : null}
                </div>
            </div>
        </motion.div>
    )
}
