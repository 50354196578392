import { z } from "zod"
import { AccountTypeSchema } from "../account-type"
import { ISO8601DateSchema, Ref } from "../common"
import { OfferPriceSchema } from "../offer-price"
import { ProductSchema } from "../product"

const OfferSchema = z.object({
    id: z.number(),
    productRef: Ref(ProductSchema),
    accountTypeRef: Ref(AccountTypeSchema),
    startDate: ISO8601DateSchema,
    endDate: ISO8601DateSchema.nullable().optional(),
    cardAttributes: z.object({
        color: z.string(),
        label: z.string(),
    }),
    stripeCouponId: z.string(),
    offerPricesRef: z.array(OfferPriceSchema),
})

type IOffer = z.infer<typeof OfferSchema>

export { OfferSchema }
export type { IOffer }
