import { z } from "zod"
import { ISO8601DateSchema, Ref } from "../common"

const UsageTrackingSchema = z.object({
    id: z.number(),
    userRef: Ref(),
    tokensUsed: z.number(),
    certificatesUsed: z.number(),
    projectsUsed: z.number(),
    startDate: ISO8601DateSchema,
    endDate: ISO8601DateSchema.nullable(),
    inactive: z.boolean(),
})

const UsageTrackingLimitSchema = z.object({
    limits: z.object({
        token: z.number(),
        projects: z.number(),
        certificates: z.number(),
    }),
})

type UsageTrackingLimitResponse = z.infer<typeof UsageTrackingLimitSchema>
type IUsageTracking = z.infer<typeof UsageTrackingSchema>

export { UsageTrackingSchema, UsageTrackingLimitSchema }
export type { IUsageTracking, UsageTrackingLimitResponse }
