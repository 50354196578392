import { useEditChatMessage } from "@api/chat/useEditChatMessage"
import { Button } from "@components/atoms/buttons"
import { Input } from "@components/atoms/input"
import { ChoiceInput } from "@components/molecules/choice-input"
import { icons } from "@components/organisms/changeable-field"
import { DataModal } from "@components/organisms/data-modal"
import * as Form from "@radix-ui/react-form"
import React from "react"

interface Props {
    chatId: number
    projectId: number
    defaultAnswer?: string
    choices?: string[]
}

export function EditAnswer(props: Props) {
    const { chatId, projectId, defaultAnswer, choices } = props
    const [open, setOpen] = React.useState(false)
    const changeOpen = (v: boolean) => {
        setOpen(v)
        if (v) {
            setAnswer(defaultAnswer || "")
        } else {
            setAnswer("")
        }
    }
    const { mutateAsync } = useEditChatMessage()

    const [answer, setAnswer] = React.useState(defaultAnswer || "")

    const onEdit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        const formData = new FormData(event.currentTarget)
        const answer = formData.get("edit-answer-input") as string
        if (typeof answer === "string") {
            const response = await mutateAsync({ answer, chatId, projectId })
            if (response) {
                setOpen(false)
            }
        }
    }

    const onChoiceSelected = async (choice: string) => {
        if (typeof answer === "string") {
            const response = await mutateAsync({ answer: choice, chatId, projectId })
            if (response) {
                setOpen(false)
            }
        }
    }

    return (
        <DataModal
            trigger={<i className="ri-edit-line cursor-pointer text-textVeryLight" onClick={() => null}></i>}
            open={open}
            onOpenChange={changeOpen}
            iconClass={icons["edit"]}
            title=""
            description=""
        >
            <Form.Root onSubmit={onEdit} className="grid gap-4 max-h-[50vh]">
                {!choices && (
                    <>
                        <Input
                            label="Antwort bearbeiten"
                            name="edit-answer-input"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                        />
                        <Form.Submit asChild className="border-black border px-8 py-2">
                            <Button variant="primary">Speichern</Button>
                        </Form.Submit>
                    </>
                )}
                {choices ? (
                    <ChoiceInput
                        choices={choices}
                        onSelectionChanged={onChoiceSelected}
                        disabled={false}
                        className="overflow-y-auto overflow-x-hidden no-scrollbar"
                    />
                ) : null}
            </Form.Root>
        </DataModal>
    )
}
