import { z } from "zod"

export const MZeileSchema = z.object({
    id: z.number(),
    Nummer: z.number().nullish(),
    Bauteil_Anlagenteil: z.string().nullish(),
    Massnahmenbeschreibung: z.string().nullish(),
    Modernisierungskombination: z.string().nullish(),
    Amortisation: z.string().nullish(),
    Spezifische_Kosten: z.string().nullish(),
})

export type IMZeile = z.infer<typeof MZeileSchema>

export const MZeilesSchema = z.array(MZeileSchema)

export type IMZeiles = z.infer<typeof MZeilesSchema>

export const MZeileInputSchema = MZeileSchema.pick({
    Nummer: true,
    Bauteil_Anlagenteil: true,
    Massnahmenbeschreibung: true,
    Modernisierungskombination: true,
    Amortisation: true,
    Spezifische_Kosten: true,
})

export type IMZeileInput = z.infer<typeof MZeileInputSchema>
