import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { IEnergyCertificateCollection } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

export function useCreateCertificateByToken(certificateId: number | string) {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async () => {
            if (!authHeader) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/energy-certificate/create-by-token`, {
                    method: "post",
                    body: JSON.stringify({
                        data: { certificateId },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async (data: IEnergyCertificateCollection) => {
            await queryClient.invalidateQueries({
                queryKey: ["certificate"],
            })
            await queryClient.invalidateQueries({
                queryKey: ["draft", data.id],
            })

            toast.success("Ausweis wird erstellt!")
        },
    })
}
