import { z } from "zod"

const BenefitSchema = z.object({
    id: z.number(),
    projects: z.number(),
    certificates: z.number(),
    token: z.number(),
})

type IBenefit = z.infer<typeof BenefitSchema>

export { BenefitSchema }
export type { IBenefit }
