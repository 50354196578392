import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

export type WillLoseTrialResource = "certificate" | "token" | "project"
type WillLoseTrialStatus = "will-loose" | "has-lost" | "available" | "invalid"
type WillLoseTrialResponse = { status: WillLoseTrialStatus }

export function useWillLoseTrial(params: { resource: WillLoseTrialResource }) {
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["trial", authHeader, params.resource],
        queryFn: async (): Promise<WillLoseTrialResponse | null> => {
            const response: WillLoseTrialResponse = await handleFetch(async () => {
                const response = await fetch(
                    `${apiUrl}/user-subscription/will-lose-trial?resource=${params.resource}`,
                    {
                        method: "GET",
                        headers: {
                            ...authHeader,
                        },
                    }
                )

                if (response.status === 404) {
                    return null
                }

                return response
            })

            if (!response) {
                return null
            }

            return response
        },
        enabled: !!authHeader,
    })
}

export const invalidateWillLoseTrialQuery = async (
    authHeader: ReturnType<typeof useAuthHeader>,
    resource: WillLoseTrialResource
) => {
    await queryClient.invalidateQueries({
        queryKey: ["trial", authHeader, resource],
    })
}
