import * as Tooltip from "@radix-ui/react-tooltip"

interface PricingCardAttributeProps {
    title: string
    value: PricingCardAttributeValue
}
type PricingCardAttributeValue = number | "unbegrenzt" | { time: number; timeUnit: string }

function PricingCardAttribute(props: PricingCardAttributeProps) {
    const { title, value } = props

    return (
        <div className="flex w-full justify-between">
            <span>{title}</span>
            <div className="flex gap-2">
                <Value value={value} />
            </div>
        </div>
    )
}
function Value(props: { value: PricingCardAttributeValue }) {
    const { value } = props

    if (typeof value === "number") {
        return <span>{new Intl.NumberFormat("de-DE").format(value)}</span>
    } else if (value === "unbegrenzt") {
        return (
            <div className="flex gap-2">
                <span>unbegrenzt</span>
                <span>
                    <Tooltip.Provider>
                        <Tooltip.Root>
                            <Tooltip.Trigger>
                                <i className="ri-information-line"></i>
                            </Tooltip.Trigger>
                            <Tooltip.Portal>
                                <Tooltip.Content>
                                    <div className="flex flex-col gap-2 rounded bg-white px-4 py-2 text-tiny text-backgroundLight">
                                        Siehe AGB
                                    </div>
                                </Tooltip.Content>
                            </Tooltip.Portal>
                        </Tooltip.Root>
                    </Tooltip.Provider>
                </span>
            </div>
        )
    } else if (typeof value === "object") {
        return (
            <div className="flex gap-2">
                <span>
                    {value.time} {value.timeUnit}
                </span>
            </div>
        )
    } else {
        console.error("Invalid value type")
        return <span></span>
    }
}

export { PricingCardAttribute }
export type { PricingCardAttributeValue, PricingCardAttributeProps }
