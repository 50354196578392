import { z } from "zod"
import { Ref } from "../common"

enum EnterpriseSubscriptionId {
    S = 3,
    M = 4,
    L = 5,
    Free = 6,
}
enum EnterpriseSubscription {
    S = "S",
    M = "M",
    L = "L",
    Free = "Free",
}

enum PrivateSubscriptionId {
    Free = 1,
    Pro = 2,
}
enum PrivateSubscription {
    Free = "Free",
    Pro = "Pro",
}

enum SubscriptionInterval {
    Monthly = "monthly",
    Yearly = "yearly",
}
const SubscriptionIntervalSchema = z.nativeEnum(SubscriptionInterval)

interface SubscriptionPost {
    subscriptionId: PrivateSubscriptionId | EnterpriseSubscriptionId
    interval: SubscriptionInterval
}
const SubscriptionSchema = z.object({
    id: z.number(),
    name: z.union([z.nativeEnum(PrivateSubscription), z.nativeEnum(EnterpriseSubscription)]),
    monthlyPrice: z.number(),
    annualPrice: z.number(),
    description: z.string(),
    attributes: z.array(
        z.object({
            title: z.string(),
            value: z.union([z.number(), z.object({ time: z.number(), timeUnit: z.string() }), z.literal("unbegrenzt")]),
        })
    ),
    accountTypeRef: Ref(),
    benefitRef: Ref(),
    isActive: z.boolean(),
})
type ISubscription = z.infer<typeof SubscriptionSchema>

export {
    PrivateSubscriptionId,
    EnterpriseSubscriptionId,
    EnterpriseSubscription,
    PrivateSubscription,
    SubscriptionInterval,
    SubscriptionSchema,
    SubscriptionIntervalSchema,
}
export type { SubscriptionPost, ISubscription }
