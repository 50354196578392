import { App, URLOpenListenerEvent } from "@capacitor/app"
import React from "react"
import { useNavigate } from "react-router"

export function useDeepLinkRedirect() {
    const navigate = useNavigate()

    React.useEffect(() => {
        void App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
            const url = new URL(event.url)
            const path = `${url.pathname}${url.search}`
            navigate(path)
        })
    }, [navigate])
}
