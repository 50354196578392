import { z } from "zod"
import { ISO8601DateSchema, Ref } from "../common"
import { SubscriptionInterval, SubscriptionSchema } from "../subscription"

enum UserSubscriptionStatus {
    Active = "active",
    Canceled = "canceled",
    ActiveCanceled = "active_canceled",
}

const userSubscriptionStatusSchema = z.nativeEnum(UserSubscriptionStatus)

const UserSubscriptionSchema = z.object({
    id: z.number(),
    userRef: Ref(),
    subscriptionRef: Ref(SubscriptionSchema),
    orderRef: Ref(),
    stripeSubscriptionId: z.string().nullable().optional(),
    startDate: ISO8601DateSchema,
    endDate: ISO8601DateSchema.nullable(),
    status: z.enum(["active", "canceled", "active_canceled"]),
    interval: z.nativeEnum(SubscriptionInterval),
    inTrial: z.boolean().optional().nullable(),
})

type IUserSubscription = z.infer<typeof UserSubscriptionSchema>

const getSubscriptionInterval = (userSubscription: IUserSubscription): SubscriptionInterval => {
    //TODO: Refactor Legacy Code - not longer needed function!
    return userSubscription.interval
}

export { UserSubscriptionSchema, getSubscriptionInterval, userSubscriptionStatusSchema, UserSubscriptionStatus }
export type { IUserSubscription }
