import { confirmation } from "@api/auth/confirmation"
import { LinkButton } from "@components/atoms/buttons/link-button.tsx"
import { EnerGuide, EnerGuideDark } from "@components/atoms/energuide"
import { Heading, Text } from "@components/atoms/typography"
import { useAppState } from "@hooks/useState.ts"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { motion } from "framer-motion"
import React from "react"
import { useNavigate } from "react-router"
import { toast } from "sonner"

export function Confirmation() {
    const navigate = useNavigate()
    useTitlebar({
        title: "",
        mode: "back",
        backTo: "/authenticated/chat",
        showContextMenu: false,
    })
    const confirmCalled = React.useRef(false)

    // clear auth in case we were redirected here due to a email change confirmation
    const clearAuth = useAppState((state) => state.clearAuth)
    const setAuthentication = useAppState((state) => state.setAuthentication)

    const onConfirm = React.useCallback(
        async (queryString: string) => {
            try {
                const { user, jwt } = await confirmation({ queryString })
                setAuthentication(jwt, user)

                if (!user?.displayName) {
                    navigate("/auth/register-additions")
                } else {
                    navigate("/authenticated/chat")
                }
            } catch (error: any) {
                toast.error("Verifizierung fehlgeschlagen")
                navigate("/auth/login")
            }
        },
        [navigate]
    )

    React.useEffect(() => {
        if (confirmCalled.current) {
            return
        }

        confirmCalled.current = true
        clearAuth()
        void onConfirm(window.location.search)
    }, [clearAuth, onConfirm])

    return (
        <>
            <EnerGuideDark
                className="absolute inset-0 z-[-1] justify-self-center overflow-hidden"
                energuideClassName="absolute left-[25%] top-[25%] z-[-1] aspect-square w-[150%]"
                layoutId="energuide-dark"
            />
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-12%] lg:top-[-22%] z-[-1] aspect-square justify-self-center w-[18rem] sm:w-[20rem] md:w-[30rem] lg:w-[22rem] xl:w-[25rem] 2xl:w-[40rem]"
                layoutId="energuide"
            />

            <div className="mx-auto grid min-h-full w-full max-w-screen-sm content-center gap-12 pt-2">
                <div className="grid gap-12 rounded-lg bg-background/20 px-8 pt-8 backdrop-blur-2xl">
                    <motion.div className="grid gap-4" layoutId="register-title">
                        <Heading level="h1">Viel Dank!</Heading>
                        <Text variant="body1" className="text-textLight">
                            Deine E-Mail Adresse ist hiermit bestätigt. Viel Spaß!
                        </Text>
                        <LinkButton
                            variant="primary"
                            href={`${import.meta.env.VITE_FRONTEND_URL}/auth/login`}
                            className="text-white underline"
                        >
                            Los geht's!
                        </LinkButton>
                    </motion.div>
                </div>
            </div>
        </>
    )
}
