import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { useUserKey } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

type EmailMutationParams = {
    data: FormData
}

export function useMutateUserEmail() {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async ({ data }: EmailMutationParams) => {
            if (!authHeader) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/auth/change-email`, {
                    method: "post",
                    body: data,
                    headers: {
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Bestätigung veschickt")
            await queryClient.invalidateQueries({
                queryKey: useUserKey,
            })
        },
    })
}
