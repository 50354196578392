import { apiUrl, handleFetch } from "@api/apiConfig"
import { OrderSchema, IOrder } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

export type IUseOrders = {
    projectId: number
}

export function useOrders(params: IUseOrders) {
    const { projectId } = params
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["order", projectId],
        queryFn: async (): Promise<IOrder[] | null> => {
            try {
                const response = await handleFetch(async () => {
                    const response = await fetch(`${apiUrl}/orders?projectId=${projectId}`, {
                        method: "GET",
                        headers: {
                            ...authHeader,
                        },
                    })
                    // don't show error when no entry found
                    if (response.status === 404) {
                        return null
                    }

                    return response
                })

                if (!response) {
                    return null
                }
                const { data } = response

                if (Array.isArray(data)) {
                    return OrderSchema.array().parse(data)
                } else {
                    return []
                }
            } catch (e) {
                console.error("outer error ", e)
                return null
            }
        },
    })
}
