import { Currency } from "@energuide/shared"

function PricingCardPricing(props: { price: Currency; discountPrice?: Currency }) {
    const { price, discountPrice } = props

    return (
        <div className="flex justify-center gap-4 items-end mt-3">
            {discountPrice && (
                <>
                    <h5 className="inline-block line-through text-textDisabled text-body1 leading-8">
                        {price.formatted}
                    </h5>
                    <h2 className="inline-block text-heading2 leading-8">{discountPrice.formatted}</h2>
                </>
            )}
            {!discountPrice && <h2 className="inline-block text-heading2 leading-8">{price.formatted}</h2>}
        </div>
    )
}
export { PricingCardPricing }
