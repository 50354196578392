import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { EnterpriseSubscriptionId, ISubscription, PrivateSubscriptionId, SubscriptionInterval } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"

type MutationParams = {
    subscriptionId: PrivateSubscriptionId | EnterpriseSubscriptionId
    interval?: SubscriptionInterval
}

export function useMutateUserSubscription() {
    const authHeader = useAuthHeader()
    const queryKeys = ["user-subscription", authHeader]

    return useMutation({
        mutationFn: async (params: MutationParams) => {
            if (!authHeader) {
                return { error: "No auth header provided" }
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/user-subscription/subscribe`, {
                    method: "POST",
                    body: JSON.stringify(params),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
        },
        async onMutate() {
            const previousData = queryClient.getQueryData<ISubscription[]>(queryKeys)
            await queryClient.cancelQueries({ queryKey: queryKeys })

            return { previousData }
        },
        onError: (_error, _params, context) => {
            if (context?.previousData) {
                queryClient.setQueryData(queryKeys, context.previousData)
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: queryKeys })
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: queryKeys })
        },
    })
}
