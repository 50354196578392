import { handleFetch, apiUrl, baseUrl } from "@api/apiConfig"
import { UserSchema } from "@energuide/shared"
import { useAppState } from "@hooks/useState"

export type ILogin = {
    identifier: string
    password: string
}

export async function login(params: ILogin) {
    const { user, jwt } = await handleFetch(async () =>
        fetch(`${apiUrl}/auth/local`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
        })
    )

    try {
        const avatar = user.avatar ? { ...user.avatar, url: `${baseUrl}${user.avatar.url}` } : null
        const parsedUser = UserSchema.parse({
            ...user,
            avatar,
        })
        useAppState.getState().setAuthentication(jwt, parsedUser)

        return parsedUser
    } catch (e) {
        console.error("user parse error!", e)
        // maybe catch and reroute to page where we can set these values
    }
}
