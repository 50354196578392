import { z } from "zod"
import { ProductIdSchema } from "../product"

const orderPostSchema = z.object({
    email: z.string().email(),
    productId: ProductIdSchema,
    projectId: z.number().positive(),
    certificateId: z.number(),
})

type OrderPost = z.infer<typeof orderPostSchema>

export { orderPostSchema }
export type { OrderPost }
