import { z } from "zod"
import { EnergyCertificateVariant } from "../energy-certificate/schema"
import { projectIdPattern } from "../project"
import { ProductId } from "./schema"

const productUuidPattern = `(${ProductId.ConsumptionCertificate}|${ProductId.DemandCertificate})`
const variantPattern = `(${EnergyCertificateVariant.Draft}|${EnergyCertificateVariant.Final})` // This is causing an error

const identifierPattern = new RegExp(`^${projectIdPattern}-${productUuidPattern}-${variantPattern}$`)

const ProductIdentifierSchema = z.string().regex(identifierPattern, {
    message: "Invalid identifier format. Expected format: projectId-Product.ProductId-Variant",
})

type ProductIdentifier = z.infer<typeof ProductIdentifierSchema>

export { ProductIdentifierSchema, ProductId, identifierPattern }
export type { ProductIdentifier }
