import { IContextMenu } from "@components/molecules/context-menu"
import { useAppState } from "@hooks/useState"
import { useEffect } from "react"

type IUseTitlebar = {
    title: string
    showSearchIcon?: boolean
} & (
    | {
          showContextMenu: true
          projectId: number | null
          contextMenuProps: IContextMenu
      }
    | {
          showContextMenu: false
      }
) &
    (
        | {
              mode: "back"
              backTo: string
          }
        | {
              mode: "menu" | "hide"
          }
        | {
              mode: "callback"
              onClick: () => void
          }
    )

export function useTitlebar(params: IUseTitlebar) {
    const setTitle = useAppState((state) => state.setTitle)
    const setMode = useAppState((state) => state.setMode)
    const setBackTo = useAppState((state) => state.setBackTo)
    const setShowContextMenu = useAppState((state) => state.setShowContextMenu)
    const setShowSearchIcon = useAppState((state) => state.setShowSearchIcon)
    const setActiveProject = useAppState((state) => state.setActiveProject)
    const setBackToCallback = useAppState((state) => state.setBackToCallback)

    useEffect(() => {
        setTitle(params.title)
        setMode(params.mode)
        if (params.mode === "back") {
            setBackTo(params.backTo)
        }
        if (params.mode === "callback") {
            setBackToCallback(params.onClick)
        } else {
            setBackToCallback(null)
        }
        setShowContextMenu(params.showContextMenu, params.showContextMenu ? params.contextMenuProps : undefined)
        setShowSearchIcon(params.showSearchIcon ?? false)
        if (params.showContextMenu) {
            setActiveProject(params.projectId)
        }
    }, [
        setTitle,
        setMode,
        setBackTo,
        setShowContextMenu,
        setActiveProject,
        setShowSearchIcon,
        setBackToCallback,
        params,
    ])

    return {
        updateTitle: setTitle,
    }
}
